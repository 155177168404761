<template>
  <b-container class="icons-container m-auto">
    <!--    Because a lot of accounts don't have stageline yet- but still need notifs-->
    <notification-manager v-if="showNotification" />
    <h1 data-cy="title" class="ml-0">
      Welcome to your account
    </h1>
    <div v-if="loading" class="row ml-1 dashpanel-item-container">
      <div class="col-sm-12 my-auto mt-10 fadeOut">
        <b-progress key="secondary" :value="progress" variant="secondary" class="mx-auto w-25" />
      </div>
    </div>
    <b-row v-if="!loading" class="tiles-container">
      <dash-panel-item
        v-for="(item, index) in tiles" :key="index"
        :badge="badgeForType(item.type)"
        :title="item.title"
        :type="item.type"
      />
    </b-row>
    <capture-odd-data />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dashpanel',
  components: {
    CaptureOddData:      () => import('@/components/CaptureOddData'),
    DashPanelItem:       () => import('@/components/DashpanelItem'),
    NotificationManager: () => import('@/components/NotificationManager'),
  },
  data() {
    return {
      items: {},
      defaultTiles: [
        { title: 'Unread Documents', type: 'unreadDocuments' },
        { title: 'Unpaid Invoices', type: 'unpaidInvoices' },
        { title: 'Services Requiring Attention', type: 'servicesRequiringAttention' },
        { title: 'Filing Orders', type: 'pendingFilings' },
        { title: 'Manage Your Businesses', type: 'incompleteStagelineStages' },
        { title: 'Add Services', type: 'hireUs' },
        { title: 'FAQ', type: 'faq' },
        { title: 'Contact Support', type: 'contactSupport' },
      ],
      loading: true,
      progress: 0,
      isMontana: false,
      newYorkServiceCompanies: {},
    }
  },
  computed: {
    ...mapGetters('account', ['isAccountWholesaler']),
    ...mapGetters('dashpanel', ['isEntityTypeDomains']),
    updatedTiles() {
      return this.defaultTiles.map(tile => {
        if (tile.type === 'incompleteStagelineStages') {
          tile.title = `Manage Your ${this.isEntityTypeDomains ? 'Domains' : 'Businesses'}`
        }
        return tile
      })
    },
    tiles() {
      let allTiles = [...this.updatedTiles]

      if (this.isMontana) {
        allTiles = allTiles.filter(tile => tile.type !== 'incompleteStagelineStages')
      }

      allTiles = this.setupVehicleTiles(allTiles)
      allTiles = this.setupWholesalerTiles(allTiles)

      return allTiles
    },
    showNotification() {
      return !this.loading
    },
  },
  async mounted() {
    this.loading = true
    let that = this
    that.timeout = setInterval(() => {
      that.progress < 100 ? that.progress += 1 : clearInterval(that.timeout)
    }, 100)

    await this.getItems()

    that.loading = false
    clearInterval(that.timeout)
  },
  methods: {
    async getItems() {
      this.loading = true
      try {
        const result = await this.$store.dispatch('dashpanel/refresh')

        this.loading = false
        this.items = result.data.summary
        this.loadAdditionalItems()

        await this.$store.dispatch('account/setServicesRequiringAttentionCount', this.items?.servicesRequiringAttention || 0)

        return result
      } catch (error) {
        this.loading = false
        this.items = {
          unreadDocuments: 0,
          unpaidInvoices: 0,
          // TODO periods? steps?
          incompleteStagelineStages: 0,
          servicesRequiringAttentionCount: 0,
          orderItemsRequiringAttentionCount: 0,
          pendingFilings: 0,
          pendingVehicleOrders: 0,
          upcomingComplianceEvents: 0,
        }
      }
    },
    loadAdditionalItems() {
      this.newYorkServiceCompanies = this.items['newYorkServiceCompanies']
      this.$delete(this.items, 'newYorkServiceCompanies')
      this.isMontana = this.items.isMontanaAccount
      this.$delete(this.items, 'isMontanaAccount')
      this.$delete(this.items, 'orderItemsRequiringAttention')
    },
    badgeForType(type) {
      const badge = this.items[type]
      return badge || 0
    },
    setupVehicleTiles(allTiles) {
      if (this.isMontana) {
        const vehicleOrders = [
          { title: 'Vehicle Orders', type: 'pendingVehicleOrders' },
        ]
        allTiles.splice(4, 0, ...vehicleOrders)
      }
      return allTiles
    },
    setupWholesalerTiles(allTiles) {
      // RA-633: wholesaler display of Forms Library tile instead of Manage Your Businesses
      if (this.isAccountWholesaler) {
      allTiles = allTiles.map(item =>
          item.title === 'Manage Your Businesses' ? { ...item, title: 'Forms Library', type: 'formsLibrary' } : item
        )
      }
      return allTiles
    },
  },
}
</script>

<style lang="scss" scoped>

.icons-container {
  width: 100% !important;
  max-width: 84.375rem !important;
  padding-left: 1em;

  h1 {
    margin: 1em;
    text-align: center;
    padding-left: 1em;
  }

  .tiles-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row {
    margin-bottom: 3rem;
  }

  .list-group-item {
    border: 0 !important;
  }

  .dashpanel-item-container {
    margin-top: 15.625em !important;
  }
}

@media only screen and (max-width: 550px) {
  h1 {
    font-size: 1.875em !important;
    margin: 2.0em 0.5em 1.0em 0.5em;
  }

  h5 {
    font-size: 110%;
  }
}
</style>
