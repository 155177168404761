var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "icons-container m-auto" },
    [
      _vm.showNotification ? _c("notification-manager") : _vm._e(),
      _c("h1", { staticClass: "ml-0", attrs: { "data-cy": "title" } }, [
        _vm._v("\n    Welcome to your account\n  "),
      ]),
      _vm.loading
        ? _c("div", { staticClass: "row ml-1 dashpanel-item-container" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 my-auto mt-10 fadeOut" },
              [
                _c("b-progress", {
                  key: "secondary",
                  staticClass: "mx-auto w-25",
                  attrs: { value: _vm.progress, variant: "secondary" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "b-row",
            { staticClass: "tiles-container" },
            _vm._l(_vm.tiles, function (item, index) {
              return _c("dash-panel-item", {
                key: index,
                attrs: {
                  badge: _vm.badgeForType(item.type),
                  title: item.title,
                  type: item.type,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c("capture-odd-data"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }